<template>
  <div id="app">
    <div class="home">
      <el-backtop :visibility-height="1200"></el-backtop>
      <div class="content"></div>
    </div>
    <!-- 导航栏 -->
    <div class="nav">
      <nav>
        <div>成都每日宅选有限公司</div>
        <ul>
          <li><router-link to="/index">首页</router-link></li>
          <li><router-link to="/fruit">水果</router-link></li>
          <li><router-link to="/seafood">海鲜</router-link></li>
          <li><router-link to="/nut">坚果</router-link></li>
          <li><router-link to="/login">登录</router-link></li>
          <li>
            <router-link to="/release"
              ><el-button size="small" type="danger"
                >发布信息</el-button
              ></router-link
            >
          </li>

          <li style="position: absolute; right: 0">
            <el-dropdown @command="loginOut">
              <el-avatar
                shape="circle"
                size="large"
                :src="circleUrl"
              ></el-avatar>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item v-if="boo">退出登录</el-dropdown-item>
                <el-dropdown-item v-else>登录</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </li>
        </ul>
      </nav>
    </div>
    <!-- 一级路由出口 -->
    <router-view></router-view>

    <div class="floor">
      <div class="address">
        <div>公司名称：成都每日宅选有限公司</div>
        <div>地址: 成都市锦江区金石路366号 电话: 400-012-8058</div>
        <div>蜀ICP备2023009249号</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      boo: false,
      circleUrl:
        "https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png",
    };
  },
  methods: {
    loginOut() {
      sessionStorage.setItem("login", false);
      this.$message({
        message: "退出登录成功",
        type: "success",
        duration: 1000,
      });
    },
  },
  created() {
    this.boo = sessionStorage.getItem("login");
    console.log(this.boo);
    if (this.boo) {
      this.circleUrl = "https://ps.ssl.qhimg.com/t019151d82f5786cf8d.jpg";
    }
  },
};
</script>

<style lang="less" scoped>
#app {
  width: 100%;
  height: 100%;
}
.nav {
  padding: 15px 0;
  display: flex;
  justify-content: center;
  border-bottom: 2px solid #dde5f0;
}
nav {
  color: #213366;
  height: 60px;
  font-size: 20px;
  width: 80%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  div:hover {
    color: #f35078;
    cursor: pointer;
  }
}
.router-link-active {
  color: #f35078;
}
ul {
  font-size: 16px;
  font-weight: 600;
  display: flex;
  align-items: center;
  li {
    padding: 10px 25px;
  }
  a:hover {
    color: #f35078;
    cursor: pointer;
  }
}

.floor {
  background-color: #213366;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  box-sizing: content-box;
  .address {
    color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
</style>