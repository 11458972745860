import Vue from 'vue'
import VueRouter from 'vue-router'


Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/index'
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/login/login.vue')
  },

  {
    path: '/index',
    name: 'index',
    component: () => import('../views/index/index.vue')
  },
  {
    path: '/fruit',
    name: 'fruit',
    component: () => import('../views/fruit/fruit.vue')
  },
  {
    path: '/seafood',
    name: 'seafood',
    component: () => import('../views/seafood/seafood.vue')
  },
  {
    path: '/nut',
    name: 'nut',
    component: () => import('../views/nut/nut.vue')
  },
  {
    path: '/goodsDetail',
    name: 'goodsDetail',
    component: () => import('../views/goodsDetail/goodsDetail.vue')
  },
  {
    path: '/Release',
    name: 'Release',
    component: () => import('../views/release/release.vue')
  },

]

const router = new VueRouter({
  routes,
  mode: 'hash'
})

export default router
